<template>
  <v-container fluid v-if="authUser">
    <v-row class="pb-0 pb-sm-10">
      <v-col cols="12" v-if="authUser.role === 'admin' || authUser.role === 'superuser'">
        <div class="display-1">Личный кабинет админа</div>
      </v-col>
      <v-col cols="12" v-if="authUser.role === 'teamlead'">
        <div class="display-1">Личный кабинет тимлида</div>
      </v-col>
      <v-col cols="12" v-if="authUser.role === 'user'">
        <div class="display-1">Личный кабинет пользователя</div>
      </v-col>
      <v-col cols="12" v-if="authUser.role === 'executor'">
        <div class="display-1">Личный кабинет разработчика приложений</div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="12" xl="10">
        <v-card class="pa-md-4 card_cl">
          <v-card-title class="headline">
            Личная информация
          </v-card-title>
          <v-card-text class="pl-md-10 pr-md-10">
            <v-row>
              <v-col cols="12" xl="5" lg="5" md="5" sm="12" class="pa-6">
                <v-text-field
                  v-model="authUser.name"
                  v-validate="'required|max:50'"
                  data-vv-name="surname"
                  required
                  label="Имя"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="authUser.phone"
                  v-validate="'required'"
                  data-vv-name="phone"
                  required
                  v-mask="'+7 (9##) ###-##-##'"
                  label="Телефон"
                  outlined
                >
                </v-text-field>
              </v-col>
              <div class="hidden-sm-and-down">
                <v-divider vertical></v-divider>
              </div>

              <v-col cols="12" xl="5" lg="5" md="5" sm="12" class="pa-6">
                <v-row no-gutters>
                  <v-col cols="undefined">
                    <v-text-field
                      v-model="authUser.email"
                      v-validate="'required|email'"
                      data-vv-name="email"
                      required
                      label="Email"
                      outlined
                    >
                    </v-text-field>
                    <v-text-field
                      v-if="isAdmin"
                      v-model="authUser.api_key"
                      data-vv-name="api_key"
                      required
                      ref="api_key"
                      label="Api ключ"
                      outlined
                      readonly
                      @click="copyValue(authUser.api_key)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end">
              <v-btn
                color="info"
                outlined
                style="margin-top: 12px;"
                @click="changePasswordModal()"
              >
                Изменить пароль
              </v-btn>
              <v-btn
                color="primary"
                style="margin-left: 12px; margin-top: 12px;"
                @click="saveProfile()"
              >
                Сохранить
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="isAdmin">
      <v-col cols="12" lg="12" xl="10">
        <v-card class="pa-md-4 card_cl">
          <v-card-title class="headline">
            Ссылки для регистрации пользователей
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" xl="5" lg="5" md="5" sm="12" class="pa-6">
                <v-text-field
                  v-model="link.expires_at"
                  data-vv-name="expires_at"
                  dense
                  required
                  label="Время действия ссылки"
                  outlined
                ></v-text-field>
              </v-col>
              <div class="hidden-sm-and-down">
                <v-divider vertical></v-divider>
              </div>
              <v-col cols="12" xl="5" lg="5" md="5" sm="12" class="pa-6">
                <v-select
                  outlined
                  dense
                  hide-details
                  :items="roles"
                  item-value="value"
                  item-text="name"
                  label="Роль"
                  v-model="link.role"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="8" lg="5" md="5" sm="12" class="pa-6">
                <v-text-field
                  v-model="link.url"
                  data-vv-name="url"
                  required
                  ref="url"
                  label="Ссылка на регистрацию"
                  outlined
                  readonly
                  @click="copyValue(link.url)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xl="4" lg="5" md="5" sm="12" class="pa-6">
                <v-btn
                  color="primary"
                  style="margin-left: 12px; margin-top: 12px;"
                  @click="createLink()"
                >
                  Сгенерировать ссылку
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" lg="12" xl="10">
        <v-card class="pa-md-4 card_cl">
          <v-card-title class="headline" style="word-break: break-word">
            <p>Если вам требуется помощь по данному сервису или вы нашли какой-то баг, то обращайтесь в телеграмм с помощью кнопки ниже. </p>
            <p>Если же вам требуются туториалы на данный сервис, то они находятся на ютуб канале. </p>
          </v-card-title>

          <div class="has-text-centered">
            <button class="button is-info" v-on:click="redirect(`https://t.me/physicist_liketotravel`)" style="margin: 15px;">
              <span class="icon">
                <i class="fab fa-telegram"></i>
              </span>
              <span>Telegram</span>
            </button>

              <button class="button is-danger" v-on:click="redirect(`https://youtube.com/playlist?list=PL5wLpZXUpmC73DmJ1qs0-Fq6VWsnpOSgD`)" style="margin: 15px;">
                <span class="icon">
                  <i class="fab fa-youtube"></i>
                </span>
                <span>YouTube</span>
              </button>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <ChangePasswordModal></ChangePasswordModal>
  </v-container>
</template>

<script>
import ChangePasswordModal from "@/app/client/profiles/shared/modals/changePassword";

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "Profile",
  components: {
    ChangePasswordModal,
  },
  data() {
    return {
    };
  },
  computed: {
    authUser() {
      return this.$store.getters.AUTH_USER;
    },
    link() {
      return this.$store.getters.GET_LINK;
    },
    isAdmin() {
      return this.$store.getters.IS_ADMIN;
    },
    formValid() {
      this.$validator.validateAll();
      return Object.keys(this.fields).every(
        (field) => this.fields[field] && this.fields[field].valid
      );
    },
    roles() {
      return (this.authUser.role === 'admin' || this.authUser.role === 'superuser') ? [
        {
          name: "Администратор",
          value: "admin",
        },
        {
          name: "Тимлид",
          value: "teamlead",
        },
        {
          name: "Пользователь",
          value: "user",
        },
        {
          name: "Разработчик приложений",
          value: "executor",
        },
      ] : [{
        name: "Пользователь",
        value: "user",
      },
      ];
    },
  },
  methods: {
    changePasswordModal() {
      this.$store.dispatch("CHANGE_PASSWORD_MODAL", true);
    },
    saveProfile() {
      this.$store
        .dispatch("UPDATE_PROFILE", this.authUser)
        .then(() => {
          this.$toast.success("Пользователь изменен!");
          this.$store.commit('SET_DEFAULT_PROFILE');
        })
        .catch(() => {
          this.$toast.error("Неизвестная ошибка!");
        });
    },
    createLink() {
      if (this.link.role){
        this.$store.dispatch("GET_LINK")
          .then(() => {
            this.$toast.success('Ссылка успешно создана');
          })
          .catch(() => {
            this.$toast.error('Ошибка при создании ссылки');
          });
      }
      else{
        this.$toast.error('Заполните роль!');
      }
    },
    copyValue(value) {
      if (value) {
        try {
          navigator.clipboard.writeText(value);
          this.$toast.success('Значение успешно скопировано!');
        } catch (error) {
          this.$toast.error('Ошибка при копировании!');
        }
      }
    },
    redirect(url) {
      window.open(url, '_blank');
    },
  },
};
</script>
